<template>
  <div class="organization">
    <div class="man-title">
      知识库列表
      <el-button @click="add()" class="btn" type="primary" size="mini" :disabled="roleId == 3 || roleId == 4">
        创建内容
      </el-button>
    </div>
    <div class="table-tree">
      <el-tabs v-model="activeName" @tab-click="handleClick" tab-position="left" style="width: 100%;height: 100%;">
        <el-tab-pane :label="item.module" :name="numToStr(item.id)" v-for="(item, index) in columnNav" :key="index">
          <div class="table-list">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column label="序号" type="index" width="50"></el-table-column>
              <el-table-column prop="title" label="名称"></el-table-column>
              <el-table-column prop="knowledgeType" label="类型">
                <template slot-scope="scope">
                  {{scope.row.knowledgeType=='video' ? '视频' : scope.row.knowledgeType=='document' ? '文档' : '' }}
                </template>
              </el-table-column>
              <el-table-column prop="contentDescribe" label="摘要"></el-table-column>
              <el-table-column prop="source" label="来源"></el-table-column>
              <el-table-column prop="creator" label="创建人"></el-table-column>
              <el-table-column prop="createTime" label="创建时间">
                <template slot-scope="scope">
                  {{scope.row.createTime ? dayjs(scope.row.createTime).format("YYYY-MM-DD HH:mm:ss") : ""}}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="180">
                <template slot-scope="scope">
                  <el-dropdown placement="bottom-start" trigger="click">
                    <span @click.stop="">
                      <span class="el-dropdown-link">操作<i class="el-icon-arrow-down"></i></span>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item><div @click="details(scope.row)">详情</div></el-dropdown-item>
                      <el-dropdown-item :disabled="roleId == 3 || roleId == 4"><div @click="edit(scope.row)">编辑</div></el-dropdown-item>
                      <el-dropdown-item :disabled="roleId == 3 || roleId == 4"><div @click="dlt(scope.row)">删除</div></el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
            <!-- <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:pageChange="pageChange"></page> -->
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import page from "@/components/page.vue";
import dayjs from "dayjs";
export default {
  components: {
    page,
  },
  data() {
    return {
      activeName: '',
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      columnNav: [],
      tableData: [],
      staffId: 0,
      roleId: 0
    }
  },
  methods: {
    dayjs,
    add() {
      this.$router.push('/knowledgeList/add')
    },
    handleClick(tab){
      this.activeName = tab.name
      sessionStorage.setItem('activeName',this.activeName)
      this.loadTableData(this.activeName);
    },
    details(row) {
      this.$router.push({
        name: "knowledgeListDetail",
        params: { row: row },
      });
    },
    edit(row) {
      this.$router.push({name: "knowledgeListEdit", params: { row: row }})
    },
    dlt(row) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$ajax.post("knowledgeDelete", {}, {}, row.id).then((res) => {
          this.loadTableData(this.activeName);
        });
      });
    },
    pageChange(val) {
      this.page = val;
      sessionStorage.setItem('currentPage', val)
      this.loadTableData(this.activeName);
    },
    // 获取侧边栏
    loadList() {
      this.loading = true
      this.$ajax.get("columnClassify").then((res) => {
        this.columnNav = res.data;
        if(sessionStorage.getItem('activeName')) {
          this.activeName = sessionStorage.getItem('activeName')
          this.loadTableData(this.activeName);
        }else {
          if(res.data.length>0) {
            this.activeName = this.numToStr(res.data[0].id)
            this.loadTableData(this.activeName);
          }
        }
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    // 获取右侧表格数据
    loadTableData(moduleId) {
      this.loading = true
      this.$ajax.post("knowledgeList", {}, {}, parseInt(moduleId)).then((res) => {
        this.tableData = res.data;
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    getLastPage() {
      if(sessionStorage.getItem('detail')) {
        this.page = Number(sessionStorage.getItem('currentPage'))
      }else {
        this.page = 1
        sessionStorage.removeItem('currentPage')
      }
    },
    numToStr(num) {
      num = num.toString()
      return num
    }
  },
  mounted() {
    this.staffId = sessionStorage.getItem("id");
    this.roleId = sessionStorage.getItem('r')
    // this.getLastPage()
    this.loadList()
    
    // if(this.$route.query.active){
    //   this.activeName = this.$route.query.active
    // }else{
    //   this.activeName = this.columnNav[0].id
    // }
  },
  destroyed() {
    sessionStorage.removeItem('detail')
  }
}
</script>

<style lang="less" scoped>
.btn {
  float: right;
}
/deep/.el-tabs--left, .el-tabs--right{
    overflow: visible;
}
/deep/.el-tabs__item {
  padding: 0 40px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 56px)';
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.el-dropdown-dis {
  color: #ccc;
  cursor: not-allowed;
}
.btn {
  float: right;
}
/deep/.el-tabs--left,
.el-tabs--right {
  overflow: visible;
}
.table-tree {
  display: flex;
  height: ~"calc(100% - 56px)";
  .tree {
    width: 300px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .operate {
    &:hover {
      color: #409eff;
    }
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
.company-name {
  font-size: 18px;
  padding: 10px 0;
  margin: 0 10px;
  line-height: 28px;
  i {
    margin-left: 20px;
    cursor: pointer;
  }
}
.add-box {
  padding: 14px;
  background: #eee;
  margin-bottom: 20px;
  line-height: 28px;
  overflow: hidden;
  border: 1px solid #b1b1b1;
  img {
    vertical-align: text-bottom;
  }
  .btn {
    float: right;
  }
}
</style>

<style lang="less">
.organization {
  .el-tree-node__content {
    height: 40px;
  }
}
</style>